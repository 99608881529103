// assets
import { IconForklift } from '@tabler/icons';
// constant
const icons = {
    IconForklift
};
// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const invoice = {
    id: 'invoive',
    title: 'Invoices',
    // caption: 'Pages Caption',
    type: 'group',
    children: [

        {
            id: 'invoice-detail',
            title: 'Invoice Detail',
            type: 'collapse',
            icon: icons.IconForklift,
            children:[
                {
                    id: 'invoice-detail-item',
                    title: 'Invoice Detail',
                    type: 'item',
                    url: '/admin/invoice/invoicedetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default invoice;
