import { useRoutes } from 'react-router-dom';

// routes
import Routes from './Routes';


// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
 const { ServicesRoutes,LoginRoutes,RegisterRoutes,LandingRoutes,AdminRoutes,ResetPasswordRoutes, VarifyEmailRoutes, DefaultRoutes } = Routes();
    return useRoutes([ServicesRoutes,LoginRoutes,RegisterRoutes,LandingRoutes,AdminRoutes,ResetPasswordRoutes, VarifyEmailRoutes, DefaultRoutes]);
}
