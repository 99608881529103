// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const company = {
    id: 'company',
    title: 'companys',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'new-company',
            title: 'Add Company',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'new-company-item',
                    title: 'New Company',
                    type: 'item',
                    url: '/admin/company/newcompany',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'company-detail',
            title: 'Company Detail',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'company-detail-item',
                    title: 'Company Detail',
                    type: 'item',
                    url: '/admin/company/companydetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default company;
