// assets
import { IconCurrencyDollar } from '@tabler/icons';

// constant
const icons = {
    IconCurrencyDollar
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const factoring = {
    id: 'factoring',
    title: 'factorings',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'add-factoring',
            title: 'Add Factorings',
            type: 'collapse',
            icon: icons.IconCurrencyDollar,

            children: [
                {
                    id: 'new_factoring',
                    title: 'New Factoring',
                    type: 'item',
                    url: '/admin/factoring/newfactoring',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'factoring-detail',
            title: 'Factorings Detail',
            type: 'collapse',
            icon: icons.IconCurrencyDollar,
            children:[
                {
                    id: 'factoring-detail-item',
                    title: 'Factoring Detail',
                    type: 'item',
                    url: '/admin/factoring/factoringdetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default factoring;
