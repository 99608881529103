import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import LoginHeader from 'components/Navbars/LoginHeader';
import RegisterHeader from 'components/Navbars/signupHeader';
import MainLayout from 'layout/MainLayout';
import LandingHeader from 'components/Navbars/landingNavbar';
import MainHeader from 'components/Navbars/ExamplesNavbar';
import { Navigate } from 'react-router';
import Cookies from 'js-cookie';


const Routes = () => {

    // getting stored value
    let isAuth = '';
    const saved = Cookies.get('user');
    if (saved)
        isAuth = JSON.parse(saved);


    const DashboardDefault = Loadable(lazy(() => import('views/admin/dashboard/Default')));
    const LandingPage = Loadable(lazy(() => import('views/LandingPage')));
    const AuthLogin3 = Loadable(lazy(() => import('views/authentication/authentication3/Login3')));
    const AuthSacredCube = Loadable(lazy(() => import('views/authentication/authentication3/LoginSacredCube')));
    const AuthRegister3 = Loadable(lazy(() => import('views/authentication/authentication3/Register3')));
    const ServiceDetail1 = Loadable(lazy(() => import("components/servicesSections/service1Detail")));
    const ServiceDetail2 = Loadable(lazy(() => import("components/servicesSections/service2Detail")));
    const ServiceDetail3 = Loadable(lazy(() => import("components/servicesSections/service3Detail")));
    const ServiceDetail4 = Loadable(lazy(() => import("components/servicesSections/service4Detail")));
    const ServiceDetail5 = Loadable(lazy(() => import("components/servicesSections/service5Detail")));
    const ServiceDetail6 = Loadable(lazy(() => import("components/servicesSections/service6Detail")));
    const ServiceDetail7 = Loadable(lazy(() => import("components/servicesSections/service7Detail")));
    const ServiceDetail8 = Loadable(lazy(() => import("components/servicesSections/service8Detail")));
    const ServiceDetail9 = Loadable(lazy(() => import("components/servicesSections/service9Detail")));
    const ServiceDetail10 = Loadable(lazy(() => import("components/servicesSections/service10Detail")));
    const ServiceDetail11 = Loadable(lazy(() => import("components/servicesSections/service11Detail")));
    const Services = Loadable(lazy(() => import("views/services")));
    const UserDetail = Loadable(lazy(() => import("views/admin/user/userDetail")));
    const NewSeller = Loadable(lazy(() => import("views/admin/user/newSeller")));
    const NewAccountant = Loadable(lazy(() => import("views/admin/user/newAccountant")));
    const NewDispatcher = Loadable(lazy(() => import("views/admin/user/newDispatcher")));
    const NewSalesManager = Loadable(lazy(() => import("views/admin/user/newSalesManager")));
    const NewDispatchManager = Loadable(lazy(() => import("views/admin/user/newDispatchManager")));
    const UpdateSeller = Loadable(lazy(() => import("views/admin/user/updateSeller")));
    const UpdateDispatcher = Loadable(lazy(() => import("views/admin/user/updateDispatcher")));
    const UpdateSalesManager = Loadable(lazy(() => import("views/admin/user/updateSalesManager")));
    const UpdateDispatchManager = Loadable(lazy(() => import("views/admin/user/updateDispatchManager")));
    const NewAdmin = Loadable(lazy(() => import("views/admin/user/newAdmin")));
    const NewAgent = Loadable(lazy(() => import("views/admin/agent/newAgent")));
    const AgentDetail = Loadable(lazy(() => import("views/admin/agent/agentDetail")));
    const CustomerAdd = Loadable(lazy(() => import("views/admin/customer/CustomerAdd.js")));
    const NewCustomerPayment = Loadable(lazy(() => import("views/admin/customer/newCustomerPayment")));
    const EditCustomer = Loadable(lazy(() => import("views/admin/customer/editCustomer")));
    const NewBroker = Loadable(lazy(() => import("views/admin/broker/newBroker")));
    const BrokerDetail = Loadable(lazy(() => import("views/admin/broker/brokerDetail")));
    const NewEquipment = Loadable(lazy(() => import("views/admin/equipment/newEquipment")));
    const NewCustomerVehicle = Loadable(lazy(() => import("views/admin/equipment/newCustomerVehicle")));
    const EquipmentDetail = Loadable(lazy(() => import("views/admin/equipment/equipmentDetail")));
    const NewTeam = Loadable(lazy(() => import("views/admin/teams/newTeam")));
    const TeamDetail = Loadable(lazy(() => import("views/admin/teams/teamDetail")));
    const NewCustomerTeam = Loadable(lazy(() => import("views/admin/teams/NewCustomerTeam")));
    const ChangeCustomerTeam = Loadable(lazy(() => import("views/admin/teams/ChangeCustomerTeam")));
    const CustomerTeamDetail = Loadable(lazy(() => import("views/admin/teams/customerTeamDetail")));
    const NewTeamMember = Loadable(lazy(() => import("views/admin/teams/newTeamMember")));
    const TeamMemberDetail = Loadable(lazy(() => import("views/admin/teams/teamMemberDetail")));
    const CustomerDetail = Loadable(lazy(() => import("views/admin/customer/customerDetail")));
    const CustomerProfileDetail = Loadable(lazy(() => import("views/admin/customer/customerProfileDetail")));
    const NewCustomerProfileWizard = Loadable(lazy(() => import("views/admin/wizered/customer/newCustomerProfile")));
    const NewEmployeeProfileWizard = Loadable(lazy(() => import("views/admin/wizered/employee/newEmployeeProfile")));
    const NewCustomerPreferences = Loadable(lazy(() => import("views/admin/customer/newCustomerPreferences")));
    const CustomerPreferenceDetail = Loadable(lazy(() => import("views/admin/customer/customerpreferenceDetail")));
    const NewCustomerAttachment = Loadable(lazy(() => import("views/admin/customer/newCustomerAttachment")));
    const CustomerAttachmentDetail = Loadable(lazy(() => import("views/admin/customer/customerAttachmentDetail")));
    const NewFactoring = Loadable(lazy(() => import("views/admin/factoring/newFactoring")));
    const FactoringDetail = Loadable(lazy(() => import("views/admin/factoring/factoringDetail")));
    const NewInsurance = Loadable(lazy(() => import("views/admin/insurance/newInsurance")));
    const InsuranceDetail = Loadable(lazy(() => import("views/admin/insurance/insuranceDetail")));
    const NewLoadout = Loadable(lazy(() => import("views/admin/loadout/newLoadout")));
    const LoadoutDetail = Loadable(lazy(() => import("views/admin/loadout/loadoutDetail")));
    const NewLoadOption = Loadable(lazy(() => import("views/admin/loadoption/newLoadoption")));
    const BookLoad = Loadable(lazy(() => import("views/admin/loadoption/bookLoad")));
    const LoadOptionDetail = Loadable(lazy(() => import("views/admin/loadoption/loadoptionDetail")));
    const NewDriver = Loadable(lazy(() => import("views/admin/driver/newDriver")));
    const PasswordChange = Loadable(lazy(() => import("views/admin/user/PasswordChange")));
    const ResetPassword = Loadable(lazy(() => import('views/authentication/resetPassword/resetPassword')));
    const ResetPassword1 = Loadable(lazy(() => import('views/authentication/resetPassword/resetPassword1')));
    const ResetPassword2 = Loadable(lazy(() => import('views/authentication/resetPassword/resetPassword2')));
    const VarifyCustomer = Loadable(lazy(() => import('views/authentication/varifyCustomer/varifyCustomer')));
    const McListDetail = Loadable(lazy(() => import('views/admin/mcList/McListDetail')));
    const NewMcList = Loadable(lazy(() => import('views/admin/mcList/newMcList')));
    const NewEmployee = Loadable(lazy(() => import('views/admin/employee/newEmployee')));
    const EditEmployee = Loadable(lazy(() => import("views/admin/employee/editEmployee")));
    const EmployeeDetail = Loadable(lazy(() => import('views/admin/employee/employeeDetail')));
    const EmployeeProfileDetail = Loadable(lazy(() => import('views/admin/employee/employeeProfileDetail')));
    const CustomerPaymentDetail = Loadable(lazy(() => import('views/admin/customer/customerPaymentDetail')));
    const NewAsset = Loadable(lazy(() => import('views/admin/assets/newAsset')));
    const AssetDetail = Loadable(lazy(() => import('views/admin/assets/assetDetail')));
    const NewDepartment = Loadable(lazy(() => import('views/admin/department/newDepartment')));
    const DepartmentDetail = Loadable(lazy(() => import('views/admin/department/departmentDetail')));
    const NewCompany = Loadable(lazy(() => import('views/admin/company/newCompany')));
    const CompanyDetail = Loadable(lazy(() => import('views/admin/company/companyDetail')));
    const NewInventory = Loadable(lazy(() => import('views/admin/inventory/newInventory')));
    const InventoryDetail = Loadable(lazy(() => import('views/admin/inventory/inventoryDetail')));
    const PayrollDetail = Loadable(lazy(() => import('views/admin/payroll/payrollDetail')));
    const AllocateEmployeeItem = Loadable(lazy(() => import('views/admin/inventory/allocateEmployeeItem')));
    const AllocateDepartmentItem = Loadable(lazy(() => import('views/admin/inventory/allocateDepartmentItem')));
    const NewCommissionStructure = Loadable(lazy(() => import('views/admin/commissionStructure/newCommissionStructure')));
    const CommissionStructureDetail = Loadable(lazy(() => import('views/admin/commissionStructure/commissionStructureDetail')));
    const NewUsdToPkr = Loadable(lazy(() => import('views/admin/payroll/newUsdToPkr')));
    const UpdateTeamMember = Loadable(lazy(() => import('views/admin/teams/updateTeamMember')));
    const Attendence = Loadable(lazy(() => import('views/admin/attendence/attendenceDetail')));
    const NewShift = Loadable(lazy(() => import('views/admin/attendence/newShift')));
    const NewDptTimetable = Loadable(lazy(() => import('views/admin/attendence/newDptTimetable')));
    const NewEmpTimetable = Loadable(lazy(() => import('views/admin/attendence/newEmpTimetable')));
    const NewTimeTable = Loadable(lazy(() => import('views/admin/attendence/newTimeTable')));
    const InvoiceDetail = Loadable(lazy(() => import('views/admin/invoice/invoiceDetail')));
    const NewAccount = Loadable(lazy(() => import('views/admin/account/newAccount')));
    const AccountDetail = Loadable(lazy(() => import('views/admin/account/accountDetail')));
    const NewBill = Loadable(lazy(() => import('views/admin/bills/newBill')));
    const BillDetail = Loadable(lazy(() => import('views/admin/bills/billDetail')));
    const NewPaidBill = Loadable(lazy(() => import('views/admin/bills/newPaidBill')));
    const PaidBillDetail = Loadable(lazy(() => import('views/admin/bills/paidBillDetail')));
    const NewPayout = Loadable(lazy(() => import('views/admin/payout/newPayout')));
    const PayoutDetail = Loadable(lazy(() => import('views/admin/payout/payoutDetail')));
    const NewExpense = Loadable(lazy(() => import('views/admin/expenses/newExpense')));
    const ExpenseDetail = Loadable(lazy(() => import('views/admin/expenses/expenseDetail')));
    const BalanceSheet = Loadable(lazy(() => import('views/admin/payment/balanceSheet')));
    const GrossDetail = Loadable(lazy(() => import('views/admin/payment/grossDetail')));


    const LandingRoutes = {
        path: '/',
        element: <LandingHeader />,
        children: [
            {
                path: '/',
                element: <LandingPage />
            }
        ]
    };


    const ResetPasswordRoutes = {
        path: '/resetpassword',
        element: <LoginHeader />,
        children: [
            {
                path: '/stepone',
                element: <ResetPassword />
            },
            {
                path: '/steptwo',
                element: <ResetPassword1 />
            },
            {
                path: '/stepthree',
                element: <ResetPassword2 />
            }
        ]
    };
    const LoginRoutes = {
        path: '/login-page',
        element: isAuth && isAuth.isConfirmed ? <Navigate to="/" replace={true} /> : <LoginHeader />,
        children: [
            {
                path: '/',
                element: <AuthLogin3 />
            }
        ]
    };

    const VarifyEmailRoutes = {
        path: '/varifyemail',
        element: !isAuth ? <Navigate to="/login-page" replace={true} /> : isAuth && isAuth.isConfirmed ? <Navigate to="/admin/dashboard" replace={true} /> : <LandingHeader />,
        children: [
            {
                path: '/',
                element: <VarifyCustomer />
            }
        ]
    };

    const RegisterRoutes = {
        path: '/register-page',
        element: isAuth ? <Navigate to="/" replace={true} /> : <RegisterHeader />,
        children: [
            {
                path: '/',
                element: <AuthRegister3 />
            }
        ]
    };

    const ServicesRoutes = {
        path: '/services',
        element: <MainHeader />,
        children: [
            {
                path: '/list',
                element: <Services />,
            },
            {
                path: '/truckdispatching',
                element: <ServiceDetail1 />
            },
            {
                path: '/billing',
                element: <ServiceDetail2 />
            },
            {
                path: '/paperwork',
                element: <ServiceDetail3 />
            },
            {
                path: '/factoring',
                element: <ServiceDetail4 />
            },
            {
                path: '/rate',
                element: <ServiceDetail5 />
            },
            {
                path: '/safty',
                element: <ServiceDetail6 />
            },
            {
                path: '/ifta',
                element: <ServiceDetail7 />
            },
            {
                path: '/companyformation',
                element: <ServiceDetail8 />
            },
            {
                path: '/accounting',
                element: <ServiceDetail9 />
            },
            {
                path: '/invoice',
                element: <ServiceDetail10 />
            },
            {
                path: '/truckdocumentmanagement',
                element: <ServiceDetail11 />
            }
        ]
    };

    const AdminRoutes = {
        path: '/admin',
        element: isAuth && !isAuth.isConfirmed ? <Navigate to="/varifyemail" replace={true} /> : isAuth && isAuth.isConfirmed ? <MainLayout /> : <Navigate to="/login-page" replace={true} />,
        children: [
            {
                path: '/dashboard',
                element: <DashboardDefault />
            },
            {
                path: '/loadboardlogin',
                element: <AuthSacredCube />
            },
            {
                path: '/attendence',
                children: [
                    {
                        path: '/attendencedetail',
                        element: <Attendence/>
                    },
                    {
                        path: '/newshift',
                        element: <NewShift />
                    },
                    {
                        path: '/newtimetable',
                        element: <NewTimeTable />
                    },
                    {
                        path: '/newdpttimetable',
                        element: <NewDptTimetable />
                    },
                    {
                        path: '/newemptimetable',
                        element: <NewEmpTimetable />
                    },
                ]
            },
            {
                path: '/user',
                children: [
                    {
                        path: '/newadmin',
                        element: <NewAdmin />
                    },
                    {
                        path: '/changepassword',
                        element: <PasswordChange />
                    },
                    {
                        path: '/newsalesmanager',
                        element: <NewSalesManager />
                    },
                    {
                        path: '/newdispatchmanager',
                        element: <NewDispatchManager />
                    },
                    {
                        path: '/newdispatcher',
                        element: <NewDispatcher />
                    },
                    {
                        path: '/newseller',
                        element: <NewSeller />
                    },
                    {
                        path: '/newaccountant',
                        element: <NewAccountant />
                    },
                    {
                        path: '/updatesalesmanager',
                        element: <UpdateSalesManager />
                    },
                    {
                        path: '/updatedispatchmanager',
                        element: <UpdateDispatchManager />
                    },
                    {
                        path: '/updatedispatcher',
                        element: <UpdateDispatcher />
                    },
                    {
                        path: '/updateseller',
                        element: <UpdateSeller />
                    },
                    {
                        path: '/userdetail',
                        element: <UserDetail />
                    }
                ]
            },
            {
                path: '/customer',
                children: [
                    {
                        path: '/newcustomer',
                        element: <CustomerAdd />
                    },
                    {
                        path: '/newcustomerpayment',
                        element: <NewCustomerPayment />
                    },
                    {
                        path: '/editcustomer',
                        element: <EditCustomer />
                    },
                    {
                        path: '/customerdetail',
                        element: <CustomerDetail />
                    },
                    {
                        path: '/createcustomerprofile',
                        element: <NewCustomerProfileWizard />
                    },
                    {
                        path: '/customerprofile',
                        element: <CustomerProfileDetail />
                    },
                    {
                        path: '/newcustomerpreferences',
                        element: <NewCustomerPreferences />
                    },
                    {
                        path: '/customerpreferencedetail',
                        element: <CustomerPreferenceDetail />
                    },
                    {
                        path: '/newcustomerattachment',
                        element: <NewCustomerAttachment />
                    },
                    {
                        path: '/customerattachmentdetail',
                        element: <CustomerAttachmentDetail />
                    },
                ]
            },
            {
                path: '/load',
                children: [
                    {
                        path: '/newload',
                        element: <NewLoadout />
                    },
                    {
                        path: '/loaddetail',
                        element: <LoadoutDetail />
                    }
                ]
            },
            {
                path: '/payment',
                children: [
                    {
                        path: '/customerpaymentdetail',
                        element: <CustomerPaymentDetail />
                    },
                    {
                        path: '/grossdetail',
                        element: <GrossDetail />
                    },
                    {
                        path: '/balancesheet',
                        element: <BalanceSheet />
                    }
                ]
            },
            {
                path: '/bill',
                children: [
                    {
                        path: '/newbill',
                        element: <NewBill />
                    },
                    {
                        path: '/billdetail',
                        element: <BillDetail />
                    }
                ]
            },
            {
                path: '/payout',
                children: [
                    {
                        path: '/newpayout',
                        element: <NewPayout />
                    },
                    {
                        path: '/payoutdetail',
                        element: <PayoutDetail />
                    }
                ]
            },
            {
                path: '/expenses',
                children: [
                    {
                        path: '/newpaidbill',
                        element: <NewPaidBill />
                    },
                    {
                        path: '/paidbilldetail',
                        element: <PaidBillDetail />
                    },
                    {
                        path: '/newexpense',
                        element: <NewExpense />
                    },
                    {
                        path: '/expensedetail',
                        element: <ExpenseDetail />
                    }
                ]
            },
            {
                path: '/bank',
                children: [
                    {
                        path: '/newaccount',
                        element: <NewAccount />
                    },
                    {
                        path: '/accountdetail',
                        element: <AccountDetail />
                    }
                ]
            },
            {
                path: '/loadoption',
                children: [
                    {
                        path: '/newloadoption',
                        element: <NewLoadOption />
                    },
                    {
                        path: '/bookload',
                        element: <BookLoad />
                    },
                    {
                        path: '/loadoptiondetail',
                        element: <LoadOptionDetail />
                    }
                ]
            },
            {
                path: '/invoice',
                children: [
                    {
                        path: '/invoicedetail',
                        element: <InvoiceDetail />
                    }
                ]
            },
            {
                path: '/payroll',
                children: [
                    {
                        path: '/payrolldetail',
                        element: <PayrollDetail />
                    },
                    {
                        path: '/newcommissionstructure',
                        element: <NewCommissionStructure />
                    },
                    {
                        path: '/commissionstructuredetail',
                        element: <CommissionStructureDetail />
                    },
                    {
                        path: '/newusdtopkr',
                        element: <NewUsdToPkr />
                    }
                ]
            },
            {
                path: '/asset',
                children: [
                    {
                        path: '/newasset',
                        element: <NewAsset />
                    },
                    {
                        path: '/assetdetail',
                        element: <AssetDetail />
                    }
                ]
            },
            {
                path: '/employee',
                children: [
                    {
                        path: '/newemployee',
                        element: <NewEmployee />
                    },
                    {
                        path: '/employeedetail',
                        element: <EmployeeDetail />
                    },
                    {
                        path: '/createemployeeprofile',
                        element: <NewEmployeeProfileWizard />
                    },
                    {
                        path: '/editemployee',
                        element: <EditEmployee />
                    },
                    {
                        path: '/employeeprofile',
                        element: <EmployeeProfileDetail />
                    }
                ]
            },
            {
                path: '/inventory',
                children: [
                    {
                        path: '/newinventory',
                        element: <NewInventory />
                    },
                    {
                        path: '/inventorydetail',
                        element: <InventoryDetail />
                    },
                    {
                        path: '/assignempinventory',
                        element: <AllocateEmployeeItem />
                    },
                    {
                        path: '/assigndptinventory',
                        element: <AllocateDepartmentItem />
                    }
                ]
            },
            {
                path: '/department',
                children: [
                    {
                        path: '/newdepartment',
                        element: <NewDepartment />
                    },
                    {
                        path: '/departmentdetail',
                        element: <DepartmentDetail />
                    }
                ]
            },
            {
                path: '/company',
                children: [
                    {
                        path: '/newcompany',
                        element: <NewCompany />
                    },
                    {
                        path: '/companydetail',
                        element: <CompanyDetail />
                    }
                ]
            },
            {
                path: '/mclist',
                children: [
                    {
                        path: '/mclistdetail',
                        element: <McListDetail />
                    },
                    {
                        path: '/newmclist',
                        element: <NewMcList />
                    }
                ]
            },
            {
                path: '/team',
                children: [
                    {
                        path: '/newteam',
                        element: <NewTeam />
                    },
                    {
                        path: '/teamdetail',
                        element: <TeamDetail />
                    },
                    {
                        path: '/newcustomerteam',
                        element: <NewCustomerTeam />
                    },
                    {
                        path: '/changecustomerteam',
                        element: <ChangeCustomerTeam />
                    },
                    {
                        path: '/customerteamdetail',
                        element: <CustomerTeamDetail />
                    },
                    {
                        path: '/newteammember',
                        element: <NewTeamMember />
                    },
                    {
                        path: '/updateteammember',
                        element: <UpdateTeamMember />
                    },
                    {
                        path: '/teammemberdetail',
                        element: <TeamMemberDetail />
                    }
                ]
            },
            {
                path: '/equipment',
                children: [
                    {
                        path: '/newequipment',
                        element: <NewEquipment />
                    },
                    {
                        path: '/equipmentdetail',
                        element: <EquipmentDetail />
                    },
                    {
                        path: '/newcustomervehicle',
                        element: <NewCustomerVehicle />
                    }
                ]
            },
            {
                path: '/broker',
                children: [
                    {
                        path: '/newbroker',
                        element: <NewBroker />
                    },
                    {
                        path: '/brokerdetail',
                        element: <BrokerDetail />
                    }
                ]
            },
            {
                path: '/agent',
                children: [
                    {
                        path: '/newagent',
                        element: <NewAgent />
                    },
                    {
                        path: '/agentdetail',
                        element: <AgentDetail />
                    }
                ]
            },
            {
                path: '/driver',
                children: [
                    {
                        path: '/newdriver',
                        element: <NewDriver />
                    },
                    {
                        path: '/driverdetail',
                        element: <AgentDetail />
                    }
                ]
            },
            {
                path: '/insurance',
                children: [
                    {
                        path: '/newinsurance',
                        element: <NewInsurance />
                    },
                    {
                        path: '/insurancedetail',
                        element: <InsuranceDetail />
                    }
                ]
            },
            {
                path: '/factoring',
                children: [
                    {
                        path: '/newfactoring',
                        element: <NewFactoring />
                    },
                    {
                        path: '/factoringdetail',
                        element: <FactoringDetail />
                    }
                ]
            }
        ]
    };

    const DefaultRoutes = {
        path: '*',
        element: <Navigate to="/" replace={true} />
    };


    return {
        LandingRoutes,
        LoginRoutes,
        RegisterRoutes,
        AdminRoutes,
        ServicesRoutes,
        ResetPasswordRoutes,
        VarifyEmailRoutes,
        DefaultRoutes
    }

}

// ==============================|| AUTHENTICATION ROUTING ||============================== //


export default Routes;
