import dashboard from './dashboard';
import agent from './agent';
import customer from './customers';
import loadout from './loadout';
import team from './teams';
import broker from './broker';
import loadoption from './loadoption'

// ==============================|| MENU ITEMS ||============================== //

const menuItemsDispatcher = {
    items:  [dashboard, customer, loadout, loadoption, team, broker, agent]
};

export default menuItemsDispatcher;
