// assets
import { IconUser } from '@tabler/icons';
import Cookies from 'js-cookie';
// constant
const icons = {
    IconUser
};
let isAuth = '';
    const saved = Cookies.get('user');
    if (saved)
    isAuth = JSON.parse(saved);
// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const users = {
    id: 'user',
    title: 'Users',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'add-users',
            title: 'Add Users',
            type: 'collapse',
            icon: icons.IconUser,

            children: [
                {
                    id: 'new_admin',
                    title: 'New Admin',
                    type: 'item',
                    url: '/admin/user/newadmin',
                    breadcrumbs: false
                },
                {
                    id: 'new-sales-manager',
                    title: 'New Sales Manager',
                    type: 'item',
                    url: '/admin/user/newsalesmanager',
                    breadcrumbs: false
                },
                {
                    id: 'new-dispatch-manager',
                    title: 'New Dispatch Manager',
                    type: 'item',
                    url: '/admin/user/newdispatchmanager',
                    breadcrumbs: false
                },
                {
                    id: 'new-dispatcher',
                    title: 'New Dispatcher',
                    type: 'item',
                    url: '/admin/user/newdispatcher',
                    breadcrumbs: false
                },
                {
                    id: 'new-seller',
                    title: 'New Seller',
                    type: 'item',
                    url: '/admin/user/newseller',
                    breadcrumbs: false
                },
                {
                    id: 'new-accountant',
                    title: 'New Accountant',
                    type: 'item',
                    url: '/admin/user/newaccountant',
                    breadcrumbs: false
                }
            ] 
        },

        {
            id: 'users-detail',
            title: 'Users Detail',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'users-detail-item',
                    title: 'Users Detail',
                    type: 'item',
                    url: '/admin/user/userdetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default users;
