// assets
import { IconForklift } from '@tabler/icons';

// constant
const icons = {
    IconForklift
};
// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const loadout = {
    id: 'load',
    title: 'Loads',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'add-load',
            title: 'Add Load',
            type: 'collapse',
            icon: icons.IconForklift,

            children: [
                {
                    id: 'new_load',
                    title: 'New Load',
                    type: 'item',
                    url: '/admin/load/newload',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'load-detail',
            title: 'Load Detail',
            type: 'collapse',
            icon: icons.IconForklift,
            children:[
                {
                    id: 'load-detail-item',
                    title: 'Load Detail',
                    type: 'item',
                    url: '/admin/load/loaddetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default loadout;
