import dashboard from './dashboard';
import customer from './customers';
import loadout from './loadout';
import employee from './employee';
import payroll from './payroll';
import loadoption from './loadoption';
import invoice from './invoice';
import attendence from './attendence';
import users from './users';
import teams from './teams';
import account from './account';
import expenses from './expenses';
import bill from './bill';
import payout from './payout';
import payment from './payment'

// ==============================|| MENU ITEMS ||============================== //

const menuItemsAccountant = {
    items: [dashboard, attendence, account, bill, customer, employee, expenses, invoice, loadout, loadoption, payment, payout, payroll, teams, users]
};

export default menuItemsAccountant;
