// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};


// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const customer = {
    id: 'customer',
    title: 'Profile',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'customer-detail',
            title: 'Customer Detail',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'customer-detail-item',
                    title: 'Customer Detail',
                    type: 'item',
                    url: '/admin/customer/customerdetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
}

export default customer;
