// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};


// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const customer = {
    id: 'customer',
    title: 'Profile',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'add-customer',
            title: 'Add Profile',
            type: 'collapse',
            icon: icons.IconUser,

            children: [
                {
                    id: 'new_customer_profile',
                    title: 'Create New Profile',
                    type: 'item',
                    url: '/admin/customer/createcustomerprofile',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'customer-detail',
            title: 'Profile Detail',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'customer-detail-item',
                    title: 'Profile Detail',
                    type: 'item',
                    url: '/admin/customer/customerdetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
} 

export default customer;
