import dashboard from './dashboard';
import customer from './customers';
import loadout from './loadout';
import team from './teams';
import loadoption from './loadoption'

// ==============================|| MENU ITEMS ||============================== //

const menuItemsCustomer = {
    items: [dashboard, customer, loadout, loadoption, team]
};

export default menuItemsCustomer;
