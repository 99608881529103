// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const inventory = {
    id: 'inventory',
    title: 'Inventory',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'add-inventory',
            title: 'Add Inventory',
            type: 'collapse',
            icon: icons.IconUser,

            children: [
                {
                    id: 'new_inventory',
                    title: 'New Inventory',
                    type: 'item',
                    url: '/admin/inventory/newinventory',
                    breadcrumbs: false
                },
                {
                    id: 'assign_emp_inventory',
                    title: 'Assign Asset to Employee',
                    type: 'item',
                    url: '/admin/inventory/assignempinventory',
                    breadcrumbs: false
                },
                {
                    id: 'assign_dpt_inventory',
                    title: 'Assign Asset to Department',
                    type: 'item',
                    url: '/admin/inventory/assigndptinventory',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'inventory-detail',
            title: 'Inventory Detail',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'inventory-detail-item',
                    title: 'Inventory Detail',
                    type: 'item',
                    url: '/admin/inventory/inventorydetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default inventory;
