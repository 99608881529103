// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const payout = {
    id: 'payout',
    title: 'Payouts',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'new-payout',
            title: 'Add Payout',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'new-payout-item',
                    title: 'New Payout',
                    type: 'item',
                    url: '/admin/payout/newpayout',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'payout-detail',
            title: 'Payouts Detail',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'payout-detail-item',
                    title: 'Payout Detail',
                    type: 'item',
                    url: '/admin/payout/payoutdetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default payout;
