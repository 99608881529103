// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const payroll = {
    id: 'payroll',
    title: 'Payroll',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'add-payroll',
            title: 'Add Payroll',
            type: 'collapse',
            icon: icons.IconUser,

            children: [
                {
                    id: 'new_payroll',
                    title: 'New Commission Structure',
                    type: 'item',
                    url: '/admin/payroll/newcommissionstructure',
                    breadcrumbs: false
                },
                {
                    id: 'pay_recieved',
                    title: 'USD To Pkr',
                    type: 'item',
                    url: '/admin/payroll/newusdtopkr',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'payroll-detail',
            title: 'Payroll Detail',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'payroll-detail-item',
                    title: 'Payroll Detail',
                    type: 'item',
                    url: '/admin/payroll/payrolldetail',
                    breadcrumbs: false
                },
                {
                    id: 'commission-detail-item',
                    title: 'Commission Structure Detail',
                    type: 'item',
                    url: '/admin/payroll/commissionstructuredetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default payroll;
