// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const mclist = {
    id: 'mclist',
    title: 'MC List',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'new-mclist',
            title: 'Add MC List',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'new-mclist-item',
                    title: 'New MC List',
                    type: 'item',
                    url: '/admin/mclist/newmclist',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'mclist-detail',
            title: 'MC List Detail',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'mclist-detail-item',
                    title: 'MC List Detail',
                    type: 'item',
                    url: '/admin/mclist/mclistdetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default mclist;
