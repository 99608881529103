import { useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import useUrl from './useUrl';


const useAuth = () => {
  const url = useUrl();
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(() => {
    // getting stored value
    const saved = Cookies.get('user');
    let initialValue = '';
    if (saved)
      initialValue = JSON.parse(saved)
    return initialValue || "";
  });

  const logout = async () => {
    const response = await axios.get(url + "api/logout", { withCredentials: true }).catch((err) => {
      if (err || err.response) {
        console.log("error: ", err);
        console.log(err);
      }
    });
    if (response) {
      navigate("/", { replace: true });
    }

  };
  return { isAuth, logout }
}

export default useAuth;