import dashboard from './dashboard';
import customer from './customers';
import loadout from './loadout';
import team from './teams';
import mclist from './mclist';
import loadoption from './loadoption'

// ==============================|| MENU ITEMS ||============================== //

const menuItemsSalesManager = {
    items: [dashboard, mclist, customer, loadout, loadoption, team]
};

export default menuItemsSalesManager;
