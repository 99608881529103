import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import IconButton from '@mui/material/IconButton';
import ModeIcon from '@mui/icons-material/Mode';
import AddIcon from '@mui/icons-material/Add';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            shadow,
            sx = {},
            title,
            buttonProps = {},
            ...others
        },
        ref
    ) => {
        const theme = useTheme();
       const {addButton,addclick,editButton,editclick} = buttonProps;

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.primary[200] + 75,
                    ':hover': {
                        boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
                    },
                    ...sx
                }}
            >
                {/* card header and action */}
                <Grid container direction="row" display="flex" justifyContent="space-between" >
                    <Grid item >
                        {!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
                        {darkTitle && title && (
                            <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
                        )}
                    </Grid>
                    <Grid item >
                        {editButton ? <IconButton sx={{ marginTop: 2, marginRight: 2 }} onClick={editclick} aria-label="edit" component="label">
                            <ModeIcon />
                        </IconButton>
                        : null}
                        {addButton ? <IconButton sx={{ marginTop: 2, marginRight: 2 }} onClick={addclick} aria-label="edit" component="label">
                            <AddIcon />
                        </IconButton>
                        : null}
                    </Grid>
                </Grid>

                {/* content & header divider */}
                {title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
