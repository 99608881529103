// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const loadboard = {
    id: 'loadboard',
    title: 'loadboards',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'new-loadboard',
            title: 'Loadboard',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'new-loadboard-item',
                    title: 'Login',
                    type: 'item',
                    url: '/admin/loadboardlogin',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default loadboard;
