// assets
import { IconUsers } from '@tabler/icons';
// constant
const icons = {
    IconUsers
};
// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const team = {
    id: 'team',
    title: 'Teams',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'add-team',
            title: 'Add Team',
            type: 'collapse',
            icon: icons.IconUsers,

            children: [
                {
                    id: 'new_customer_team',
                    title: "Allocate Client",
                    type: 'item',
                    url: '/admin/team/newcustomerteam',
                    breadcrumbs: false
                },
                {
                    id: 'change_customer_team',
                    title: "Change Dispatcher",
                    type: 'item',
                    url: '/admin/team/changecustomerteam',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'team-detail',
            title: 'Teams Detail',
            type: 'collapse',
            icon: icons.IconUsers,
            children:[
                {
                    id: 'detail_customer_team',
                    title: "Customer's Team Detail",
                    type: 'item',
                    url: '/admin/team/customerteamdetail',
                    breadcrumbs: false
                },
                {
                    id: 'detail_team_member',
                    title: 'Team Member Detail',
                    type: 'item',
                    url: '/admin/team/teammemberdetail',
                    breadcrumbs: false
                }
            ]
        }
    ] 
};

export default team;
