import dashboard from './dashboard';
import users from './users';
import agent from './agent';
import customer from './customers';
import loadout from './loadout';
import equipment from './equipment';
import team from './teams';
import broker from './broker';
import insurance from './insurance';
import factoring from './factoring';
import driver from './driver';
import mclist from './mclist';
import employee from './employee';
import asset from './asset';
import department from './department';
import company from './company';
import inventory from './inventory';
import payroll from './payroll';
import loadoption from './loadoption';
import attendence from './attendence';
import invoice from './invoice';
import account from './account';
import expenses from './expenses';
import bill from './bill';
import payout from './payout';
import payment from './payment'
import loadboard from './loadboardlogin'

// ==============================|| MENU ITEMS ||============================== //


const menuItems = {
    items: [loadboard, dashboard, agent, asset, attendence, account, bill, broker, company, customer, department, driver, employee, equipment, expenses, factoring, insurance, inventory, invoice, loadout, loadoption, mclist, payment, payout, payroll, team, users]
};

export default menuItems;
