// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const employee = {
    id: 'employee',
    title: 'Employees',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'new-employee',
            title: 'Add Employee',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'new-employee-item',
                    title: 'New Employee',
                    type: 'item',
                    url: '/admin/employee/newemployee',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'employee-detail',
            title: 'Employee Detail',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'employee-detail-item',
                    title: 'Employee Detail',
                    type: 'item',
                    url: '/admin/employee/employeedetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default employee;
