// assets
import { IconForklift } from '@tabler/icons';

// constant
const icons = {
    IconForklift
};
// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const loadoption = {
    id: 'loadOption',
    title: 'Load Options',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'add-load-option',
            title: 'Add Load Option',
            type: 'collapse',
            icon: icons.IconForklift,

            children: [
                {
                    id: 'new_load_option',
                    title: 'New Load Option',
                    type: 'item',
                    url: '/admin/loadoption/newloadoption',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'load-option-detail',
            title: 'Load Option Detail',
            type: 'collapse',
            icon: icons.IconForklift,
            children:[
                {
                    id: 'load-option-detail-item',
                    title: 'Load Option Detail',
                    type: 'item',
                    url: '/admin/loadoption/loadoptiondetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default loadoption;
