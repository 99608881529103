// assets
import { IconMedicalCross } from '@tabler/icons';

// constant
const icons = {
    IconMedicalCross
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const insurance = {
    id: 'insurance',
    title: 'Insurances',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'add-insurance',
            title: 'Add Insurances',
            type: 'collapse',
            icon: icons.IconMedicalCross,

            children: [
                {
                    id: 'new_insurance',
                    title: 'New Insurance',
                    type: 'item',
                    url: '/admin/insurance/newinsurance',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'insurance-detail',
            title: 'Insurances Detail',
            type: 'collapse',
            icon: icons.IconMedicalCross,
            children:[
                {
                    id: 'insurance-detail-item',
                    title: 'Insurance Detail',
                    type: 'item',
                    url: '/admin/insurance/insurancedetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default insurance;
