// assets
import { IconTruck } from '@tabler/icons';

// constant
const icons = {
    IconTruck
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const equipment = {
    id: 'equipment',
    title: 'Equipments',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'add-equipment',
            title: 'Add Equipment',
            type: 'collapse',
            icon: icons.IconTruck,

            children: [
                {
                    id: 'new_equipment',
                    title: 'New Equipment',
                    type: 'item',
                    url: '/admin/equipment/newequipment',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'equipment-detail',
            title: 'Equipments Detail',
            type: 'collapse',
            icon: icons.IconTruck,
            children:[
                {
                    id: 'equipment-detail-item',
                    title: 'Equipment Detail',
                    type: 'item',
                    url: '/admin/equipment/equipmentdetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default equipment;
