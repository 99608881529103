// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Attendence = {
    id: 'attendence',
    title: 'Attendence',
    type: 'group',
    children: [
        {
            id: 'add-attendence',
            title: 'Manage Attendence',
            type: 'collapse',
            icon: icons.IconUser,

            children: [
                {
                    id: 'new_shift',
                    title: 'New Shift',
                    type: 'item',
                    url: '/admin/attendence/newshift',
                    breadcrumbs: false
                },
                {
                    id: 'new_timetable',
                    title: 'New Timetable',
                    type: 'item',
                    url: '/admin/attendence/newtimetable',
                    breadcrumbs: false
                },
                {
                    id: 'new_dpt_timetable',
                    title: 'Assign Timetable to Department',
                    type: 'item',
                    url: '/admin/attendence/newdpttimetable',
                    breadcrumbs: false
                },
                {
                    id: 'new_emp_timetable',
                    title: 'Assign Timetable to Employee',
                    type: 'item',
                    url: '/admin/attendence/newemptimetable',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'attendence-detail',
            title: 'Attendence Detail',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'attendence-detail-item',
                    title: 'Attendence Detail',
                    type: 'item',
                    url: '/admin/attendence/attendencedetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default Attendence;
