// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const bill = {
    id: 'bill',
    title: 'Bills',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'new-bill',
            title: 'Add Bill',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'new-bill-item',
                    title: 'New Bill',
                    type: 'item',
                    url: '/admin/bill/newbill',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'bill-detail',
            title: 'Bills Detail',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'bill-detail-item',
                    title: 'Bill Detail',
                    type: 'item',
                    url: '/admin/bill/billdetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default bill;
