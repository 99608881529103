// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const account = {
    id: 'account',
    title: 'Bank Accounts',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'new-account',
            title: 'Add Bank Account',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'new-account-item',
                    title: 'New Bank',
                    type: 'item',
                    url: '/admin/bank/newaccount',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'account-detail',
            title: 'Bank Account Details',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'account-detail-item',
                    title: 'Bank Detail',
                    type: 'item',
                    url: '/admin/bank/accountdetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default account;
