import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button,
} from "reactstrap";
import useAuth from "hooks/useAuth";
import LogoSection from 'layout/MainLayout/LogoSection';
import { Outlet } from 'react-router-dom';
import Customization from '../../layout/Customization';

function ExamplesNavbar() {
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const { isAuth, logout } = useAuth();

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  return (
    <>
    <Navbar
      className={classnames("fixed-top", "white")}
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <LogoSection/>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink to="/" tag={Link}>
                 Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/services/list" tag={Link}>
                 Pricing & Services
              </NavLink>
            </NavItem>
            {!isAuth || !isAuth.isConfirmed ? <NavItem>
              <Link to="/register-page">
                <Button
                  className="btn-round"
                  color="info"
                >
                  <i className="nc-icon nc-spaceship"></i> Signup
                </Button>
                </Link>
              </NavItem>: <NavItem>
              <Link to="admin/dashboard" >
                <Button
                  className="btn-round"
                  color="info"
                >
                  <i className="nc-icon nc-spaceship"></i> Dashboard
                </Button>
                </Link>
              </NavItem>}
            {isAuth && isAuth.isConfirmed ?  <NavItem>
              <NavLink style={{cursor:'pointer'}} onClick={logout} >
                 Logout
              </NavLink>
            </NavItem>
            :
            <NavItem>
              <NavLink to="/login-page" tag={Link}>
                 Login
              </NavLink>
            </NavItem>} 
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
    <Outlet />
    <Customization />
    </>
  );
}

export default ExamplesNavbar;
