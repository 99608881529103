// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const broker = {
    id: 'broker',
    title: 'Brokers',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'add-broker',
            title: 'Add Brokers',
            type: 'collapse',
            icon: icons.IconUser,

            children: [
                {
                    id: 'new_broker',
                    title: 'New Broker',
                    type: 'item',
                    url: '/admin/broker/newbroker',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'broker-detail',
            title: 'Brokers Detail',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'broker-detail-item',
                    title: 'Broker Detail',
                    type: 'item',
                    url: '/admin/broker/brokerdetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default broker;
