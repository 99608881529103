// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const customer = {
    id: 'customer',
    title: 'Customers',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'add-customer',
            title: 'Add Customer',
            type: 'collapse',
            icon: icons.IconUser,

            children: [
                {
                    id: 'new_customer',
                    title: 'New Customer',
                    type: 'item',
                    url: '/admin/customer/newcustomer',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'customer-detail',
            title: 'Customers Detail',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'customer-detail-item',
                    title: 'Customers Detail',
                    type: 'item',
                    url: '/admin/customer/customerdetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
}


export default customer;
