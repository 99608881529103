// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const driver = {
    id: 'driver',
    title: 'Driver',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'add-driver',
            title: 'Add Driver',
            type: 'collapse',
            icon: icons.IconUser,

            children: [
                {
                    id: 'new_driver',
                    title: 'New Driver',
                    type: 'item',
                    url: '/admin/driver/newdriver',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'driver-detail',
            title: 'Drivers Detail',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'driver-detail-item',
                    title: 'driver Detail',
                    type: 'item',
                    url: '/admin/driver/driverdetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default driver;
