// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const asset = {
    id: 'asset',
    title: 'Assets',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'new-asset',
            title: 'Add Asset',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'new-asset-item',
                    title: 'New Asset',
                    type: 'item',
                    url: '/admin/asset/newasset',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'asset-detail',
            title: 'Asset Detail',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'asset-detail-item',
                    title: 'Asset Detail',
                    type: 'item',
                    url: '/admin/asset/assetdetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default asset;
