// assets
import { IconForklift } from '@tabler/icons';
// constant
const icons = {
    IconForklift
};
// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const payment = {
    id: 'invoive',
    title: 'Payments',
    // caption: 'Pages Caption',
    type: 'group',
    children: [

        {
            id: 'payment-detail',
            title: 'Payments Detail',
            type: 'collapse',
            icon: icons.IconForklift,
            children:[
                {
                    id: 'balance-detail-item',
                    title: 'Balance Sheet',
                    type: 'item',
                    url: '/admin/payment/balancesheet',
                    breadcrumbs: false
                },
                {
                    id: 'gross-detail-item',
                    title: 'Gross Detail',
                    type: 'item',
                    url: '/admin/payment/grossdetail',
                    breadcrumbs: false
                },
                {
                    id: 'payment-detail-item',
                    title: 'Customer Payment Detail',
                    type: 'item',
                    url: '/admin/payment/customerpaymentdetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default payment;
