// assets
import { IconUsers } from '@tabler/icons';

// constant
const icons = {
    IconUsers
};


// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const team = {
    id: 'team',
    title: 'Teams',
    // caption: 'Pages Caption',
    type: 'group',
    children: [{
        id: 'team-detail',
        title: 'Teams Detail',
        type: 'collapse',
        icon: icons.IconUsers,
        children:[
            {
                id: 'detail_customer_team',
                title: "Team Detail",
                type: 'item',
                url: '/admin/team/customerteamdetail',
                breadcrumbs: false
            }
        ]
    }] 
};

export default team;
