// assets
import { IconUser } from '@tabler/icons';

// constant
const icons = {
    IconUser
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const expenses = {
    id: 'paidBill',
    title: 'Expenses',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'new-paidBill',
            title: 'New Expense',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'new-paidBill-item',
                    title: 'Pay Bill',
                    type: 'item',
                    url: '/admin/expenses/newpaidbill',
                    breadcrumbs: false
                },
                {
                    id: 'new-expense-item',
                    title: 'Add Other Expense',
                    type: 'item',
                    url: '/admin/expenses/newexpense',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'paidBill-detail',
            title: 'Expense Detail',
            type: 'collapse',
            icon: icons.IconUser,
            children:[
                {
                    id: 'paidBill-detail-item',
                    title: 'Paid Bill Detail',
                    type: 'item',
                    url: '/admin/expenses/paidbilldetail',
                    breadcrumbs: false
                },
                {
                    id: 'expense-detail-item',
                    title: 'Expense Detail',
                    type: 'item',
                    url: '/admin/expenses/expensedetail',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default expenses;
